/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"

import styled from "styled-components"
import About from "../components/About"
import { SocialProof, FeatureTennis, Footer } from "../sections/landing"

const PageContainer = styled.div`
  height: auto;
`

const AboutPage = ({ ...props }) => (
  <PageContainer>
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | About our company, values & origins</title>
      <meta
        name="description"
        content="AirMason is an online tool that makes it easy for companies to get new hires up to speed quickly with an amazing employee handbook."
      />
    </Helmet>
    <About />
    <Footer />
  </PageContainer>
)

export default AboutPage
