/*  eslint-disable */
import React from "react"
import {
  Layout,
  Container,
  LowerContainer,
  LeftWrapper,
  RightWrapper,
  Title,
  Br,
  Note,
  SubNote,
  FeatureImage,
  PhotosContainerRight,
  PhotosContainerLeft,
  PhotosWrapper,
  PhotoWrapper,
  MeetTheTeam,
  MeetTheTeamMobile,
  NameBlock,
  PhotosContainerLeftMobile,
} from "./components"
import Header from "src/componentsV2/sections/Header"
import WidthWrapper from "../widthWrapper"
import handbookIntro from "../../images/handbookIntro@2.png"
import launchYear from "../../images/launchYear.svg"
import featuredFont from "../../images/featuredFont.svg"

import branden from "../../images/team-webp/airmason_branden.png"
import courtney from "../../images/team-webp/airmason_courtney.png"
import holly from "../../images/team-webp/airmason_holly.png"
import jeet from "../../images/team-webp/airmason_jeet.png"
import joanne from "../../images/team-webp/airmason_joanne.png"
import judy from "../../images/team-webp/airmason_judy.png"
import justin from "../../images/team-webp/airmason_justin.png"
import kyee from "../../images/team-webp/airmason_kyee.png"

import tehsin from "../../images/team-webp/Tehsin@2.png"
import vikas from "../../images/team-webp/Vikas@2.png"

const teamProfile = {
  left: [
    { pic: tehsin, name: "Tehsin", title: "Head of Operations" },
    { pic: justin, name: "Justin", title: "VP Global Accounts" },
    { pic: kyee, name: "Kyee", title: "Creative Director" },
    { pic: vikas, name: "Vikas", title: "Head of Engineering" },
    { pic: jeet, name: "Jeet", title: "Project Manager" },
  ],
  right: [
    { pic: joanne, name: "Joanne", title: "Customer Success Manager" },
    { pic: judy, name: "Judy", title: "Product Manager" },
    { pic: branden, name: "Branden", title: "Full Stack Developer" },
    { pic: holly, name: "Holly", title: "Senior Account Manager" },
    {
      pic: courtney,
      name: "Courtney",
      title: "Business Development Executive",
    },
  ],
}

const About = () => (
  <>
    <Header />
    <Layout className="pt-90/16 md:pt-40">
      <WidthWrapper>
        <Container>
          <LeftWrapper>
            <Title>
              <p>The employee handbook — reimagined</p>
            </Title>
            <Note>
              Creating, updating and managing employee handbooks is a painful
              process. You know it, we know it.
              <Br />
              That’s why AirMason enables HR teams to create simplified digital
              handbooks with easy-to-use templates. Allowing real-time updates
              and easy management, AirMason is reimagining the employee handbook
              for culture-driven companies.
            </Note>
          </LeftWrapper>
          <RightWrapper>
            <PhotosContainerRight>
              <img src={handbookIntro} alt="handbook intro" />
            </PhotosContainerRight>
          </RightWrapper>
        </Container>
        <Container style={{ marginTop: "7rem" }}>
          <LeftWrapper>
            <Title>🎉 Launched in</Title>
            <div>
              <img src={launchYear} alt="2016" />
            </div>
          </LeftWrapper>
          <RightWrapper>
            <SubNote>
              AirMason is where 6.2K clients across multiple industries have
              gone to create digital employee handbooks for companies ranging
              from 100 - 20,000+ employees.
            </SubNote>
          </RightWrapper>
        </Container>
      </WidthWrapper>

      <Container style={{ backgroundColor: "#F0F7FE" }}>
        <WidthWrapper
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <LeftWrapper>
            <Note style={{ justifyContent: "center" }}>
              With convenient features like e-signatures, these mobile optimized
              handbooks can be created, published, updated and accessed on any
              computer and mobile device — any time, anywhere.
              <Br />
              With the ability to onboard collaborators, manage multiple
              handbooks, choose from 60+ fonts and background colours and
              seamlessly integrate tables, links and videos, AirMason makes it
              easy to customize your employee handbook to your brand guidelines.
            </Note>
          </LeftWrapper>
          <RightWrapper>
            <FeatureImage>
              <img src={featuredFont} alt="featured Font" />
            </FeatureImage>
          </RightWrapper>
        </WidthWrapper>
      </Container>

      <WidthWrapper>
        <LowerContainer>
          <PhotosContainerLeft>
            <PhotosWrapper>
              {teamProfile.left.map(member => (
                <PhotoWrapper key={member.name}>
                  <img
                    src={member.pic}
                    alt={member.name}
                    style={{ width: "100%" }}
                  />
                  <NameBlock>
                    <center style={{ fontSize: "10px" }}>{member.title}</center>
                    <center>{member.name}</center>
                  </NameBlock>
                </PhotoWrapper>
              ))}
            </PhotosWrapper>

            <PhotosWrapper>
              <MeetTheTeam>Meet the Fam ✌️</MeetTheTeam>
              {teamProfile.right.map(member => (
                <PhotoWrapper key={member.name}>
                  <img src={member.pic} alt={member.name} />
                  <NameBlock>
                    <center style={{ fontSize: "10px" }}>{member.title}</center>
                    <center>{member.name}</center>
                  </NameBlock>
                </PhotoWrapper>
              ))}
            </PhotosWrapper>
          </PhotosContainerLeft>

          <PhotosContainerLeftMobile>
            <PhotosWrapper>
              {teamProfile.left.map(member => (
                <PhotoWrapper key={member.name}>
                  <img
                    src={member.pic}
                    alt={member.name}
                    style={{ width: "100%" }}
                  />
                  <NameBlock>
                    <center style={{ fontSize: "10px" }}>{member.title}</center>
                    <center>{member.name}</center>
                  </NameBlock>
                </PhotoWrapper>
              ))}
              {teamProfile.right.map(member => (
                <PhotoWrapper key={member.name}>
                  <img src={member.pic} alt={member.name} />
                  <NameBlock>
                    <center style={{ fontSize: "10px" }}>{member.title}</center>
                    <center>{member.name}</center>
                  </NameBlock>
                </PhotoWrapper>
              ))}
            </PhotosWrapper>
          </PhotosContainerLeftMobile>

          <RightWrapper>
            <MeetTheTeamMobile>Meet the Fam ✌️</MeetTheTeamMobile>
            <Note style={{ justifyContent: "center" }}>
              AirMason is uniquely positioned to bring the best-in-class design
              and technology to HR. Each member of our team is at the top of
              their craft — with over 20+ years of experience across design,
              development, content creation and customer service.
              <Br />
              In the three years since launching AirMason we’ve gone through our
              own growing pains, which gives us a first-hand appreciation
              towards the importance of a great employee handbook.
            </Note>
          </RightWrapper>
        </LowerContainer>
      </WidthWrapper>
    </Layout>
  </>
)

export default About
